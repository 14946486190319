// Code generated by Slice Machine. DO NOT EDIT.

import dynamic from "next/dynamic";

export const components = {
  cta_image_text: dynamic(() => import("./CtaImageText")),
  cta_solutions: dynamic(() => import("./CtaSolutionsSlice")),
  cta_stack9_slice: dynamic(() => import("./CtaStack9Slice")),
  feature_simple_image: dynamic(() => import("./FeatureSimpleImage")),
  featured_cards: dynamic(() => import("./FeaturedCards")),
  features_one_row_carousel: dynamic(() => import("./FeaturesOneRowCarousel")),
  general_content: dynamic(() => import("./GeneralContent")),
  logo_cloud_clients_slice: dynamic(() => import("./LogoCloudClientsSlice")),
  media_embed: dynamic(() => import("./MediaEmbed")),
  testimonial: dynamic(() => import("./Testimonial")),
};
