"use client";

import React from "react";
import Vimeo from "@u-wave/react-vimeo";
import BaseContainer from "./elements/BaseContainer";

type Props = {
  video: string;
};

const VimeoPlayer = ({ video }: Props) => {
  return (
    <div className="my-[60px]">
      <div className="flex flex-col gap-8">
        <BaseContainer size="md">
          <div className="flex flex-col items-center gap-4">
            <h2 className="text-h2m font-bold text-primary laptop:text-h2">
              What is Stack9 and how does it work?
            </h2>
          </div>
        </BaseContainer>
        <BaseContainer size="sm">
          <Vimeo
            id="vimeo-player"
            video={video}
            className="mediaEmbed"
            keyboard
            responsive
            showPortrait={false}
            showTitle={false}
            showByline={false}
            playsInline={true}
          />
        </BaseContainer>
      </div>
    </div>
  );
};

export default VimeoPlayer;
