"use client";

import { SliceZone } from "@prismicio/react";
import { components } from "@/slices";
import { AllPageTypes } from "@/prismicio";

type Props = {
  page: AllPageTypes;
  context?: any;
};

export default function PageSliceZone({ page, context }: Props) {
  return (
    <SliceZone
      context={context}
      slices={page.data.slices}
      components={components}
    />
  );
}
